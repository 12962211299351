$color-primary: #040ca5;
$color-secondary: #3e75ff;
$color-success: #20a826;
$color-success-light: #4cdd53;
$color-body: #f7f7f7;
$color-default: #f7f7f7;
$color-blue: #f1f2fe;
$color-default: #f7f7f7;
$color-grey: rgb(221, 221, 221);
$color-yellow: #ef9600;
$color-orange: #e47d21;

.text-yellow {
  color: #ef9600;
}
