.dropdown-toggle {
  font-size: 14px !important;
}
.btn-group button.btn {
  border-radius: 10px !important;
}
.btn {
  min-height: 48px;
  border: 1px solid #c0c6d5;

  text-transform: none;
  border-radius: 10px !important;
  font-size: 16px;
  color: #c0c6d5;
  box-shadow: none;
  padding: 0 18px;
  font-weight: 600;
  transition: 300ms;
  overflow: hidden;
  position: relative;
  span {
    position: relative;
    z-index: 1;
  }

  &:not(.noeffect)::after {
    // content: "";
    // display: block;
    // position: absolute;
    // width: 100px;
    // height: 100px;
    // top: auto;
    // bottom: -50px;
    // left: -50px;
    // margin-left: 0;
    // background-image: none;
    // background: linear-gradient(
    //   to right,
    //   rgba(0, 0, 0, 0),
    //   rgb(62, 117, 255, 0.3)
    // );
    // border: none;
    // opacity: 1;
    // pointer-events: none;
    // border-radius: 50%;
    // transform-origin: left;
    // transition: transform 500ms;
    // transform: scale(0);
  }
  &:hover,
  &.active {
    &::after {
      //transform: scale(5);
    }
  }
}
.btn.btn-link {
  &:after {
    content: "";
    background: rgba(0, 0, 0, 0);
  }
}
.btn.active,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn.btn-primary {
  background: $color-primary;
  color: white;
  border-color: $color-primary;
  &:after {
    content: "";
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.1));
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: white !important;
  box-shadow: 0px 1px 6px -2px $color-primary !important;
}
.btn.btn-secondary {
  background: $color-secondary;
  color: white;
  border-color: $color-secondary;
  &:after {
    content: "";
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.1));
  }
}
.btn.btn-orange {
  background: $color-orange;
  color: white;
  border-color: $color-orange;
  &:after {
    content: "";
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.1));
  }
}

.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show > .btn-orange.dropdown-toggle {
  color: white !important;
  box-shadow: 0px 1px 6px -2px $color-orange !important;
}
.btn.btn-secondary {
  background: $color-secondary;
  color: white;
  border-color: $color-secondary;
  &:after {
    content: "";
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.1));
  }
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: white !important;
  box-shadow: 0px 1px 6px -2px $color-secondary !important;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #212121;
  background-color: #3e74fed6;
  background-image: none;
  border-color: #5c8afd;
}

.btn.btn-default {
  background: transparent;
  color: black;
  border-color: #c1c1c1;
}
.btn-default:not(:disabled):not(.disabled):active,
.btn-default:not(:disabled):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  color: black !important;
  background: white !important;
  border-color: $color-secondary;
  // box-shadow: 0px 1px 6px -2px $color-primary !important;
}

.btn.btn-success {
  background: green;
  color: white;
  border-color: green;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  background: $color-success;
  color: white;
  border-color: $color-success;
  box-shadow: 0px 1px 6px -2px $color-success !important;
}

.btn.btn-link {
  border: none;
  color: $color-primary;
}

.form-generator .btn.active {
  color: white !important;
}
.form-generator .btn.active:disabled {
  background: $color-primary;
}
.dropdown-toggle::before {
  content: "";
  border: none !important;
}

.check-btn-button {
  background: transparent !important;
  border: 1px solid transparent;
  color: black !important;
}
.form-generator .check-btn-button.active {
  border-color: $color-primary;
  color: black !important;
}
.form-generator .check-btn-button .fa {
  line-height: 17px;
  color: white;
  padding: 10px;
  border-radius: 100%;
  background: #27c99d;
  font-size: 18px;
}
.btn-small {
  min-height: auto !important;
  border: 0 !important;
  padding: 0px;
}
.paymethod-btn {
  justify-content: flex-start !important;
  align-items: center !important;
  flex-direction: row !important;

  .illustration {
    margin-right: 10px;
    margin-bottom: 2px;
  }
}
button.special-btn {
  background: linear-gradient(
    174deg,
    #ffbd51 25%,
    #ffc96f 25%,
    #ffc96f 50%,
    #ffbd51 50%,
    #ffbd51 75%,
    #ffc96f 75%
  );
  font-size: 18px !important;
  background-size: 200% 400%;
  background-position: 10% 13%;
  line-height: 18px;
}
.loading-btn {
  animation: bgroll 1s linear 0s infinite;
}

@keyframes bgroll {
  0% {
    background-position: 10% 0%;
  }

  100% {
    background-position: 0% 100%;
  }
}
#pj-sticky-btn {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  // transition: 0ms !important;
}
.text-cta {
  color: $color-secondary;
}
