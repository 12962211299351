/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  border-radius: 5px;
  padding: 10px !important;
  padding-right: 15px !important;
  height: 48px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 2px solid #c0c6d5;
  border-radius: 4px;
  margin: 10px 0;
  box-shadow: none;
  transition: 200ms;
}

.StripeElement--focus {
  border: 2px solid $color-primary !important;
  background-color: white;
  box-shadow: 0px 1px 6px -2px $color-primary !important;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: white !important;
}

.web-simplified-form .StripeElement {
  padding: 0px !important;
  padding-top: 1px !important;
  border: none !important;
  height: auto !important;
  margin: 0 !important;
}

.web-simplified-form .StripeElement--focus {
  border: none !important;
  background-color: white;
  box-shadow: none !important;
}
