body {
  // overflow-x: hidden !important;
}
main {
  //margin-top: 160px;
}

.main-nav {
  // background: white;
  // backdrop-filter: blur(5px);
  // -webkit-backdrop-filter: blur(5px);
  @media (max-width: 1100px) {
    background: rgba(255, 255, 255, 0.001);
  }
  &.scrolled {
    // box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    .front-header {
      // padding: 8px 30px;
      //max-width: 100%;

      background: white;
      & .nav-link.white {
        color: $color-primary !important;
      }
      @media (max-width: 1100px) {
        background: #ffffff45;
        backdrop-filter: blur(12px);
      }
      & .nav-item {
        box-shadow: none !important;
      }

      .main-logo {
        height: 33px;
      }
    }
  }
  .front-header {
    min-height: 82px;
    padding: 15px 30px;
    position: relative;
    transition: 400ms;
    align-items: flex-end !important;
    backdrop-filter: blur(6px);
    @media (max-width: 1500px) {
      align-items: center !important;
    }

    .header-logo {
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 4px;
      @media (max-width: 1500px) {
        margin-bottom: 0px;
        font-size: 0px;
      }
      .main-logo {
        transition: 200ms;
      }
    }

    .header-links {
      display: flex;
      ul {
        list-style: none;
        & .nav-item {
          // background: rgba(255, 255, 255, 0.5);
          border-radius: 40px;
          font-weight: 500;
          // box-shadow: 0px 5px 8px -10px black;
          @media (min-width: 1100px) {
            line-height: 21px;
            & .nav-link.white {
              color: white;
            }
          }
          &:hover {
            &.white {
              // background: $color-primary;
            }
            & a {
              text-decoration: none;
              color: $color-secondary;
            }
          }
          height: 100%;
          & .nav-link {
            padding: 0.5rem 0.7rem;
          }
        }
      }
    }
    .header-burger {
      display: none;
    }
  }
}
.feature-item {
  cursor: pointer;
  & > div {
    border-radius: 10px;
    position: relative;

    // border: 1px solid #e4e6ee;
    height: 100%;
    overflow: hidden;
    & > div {
      position: relative;
      z-index: 1;
      header p {
        text-align: center;
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100px;
      height: 100px;
      top: auto;
      bottom: -50px;
      left: 0;
      margin-left: 0;
      background-image: none;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(62, 117, 255, 0.3)
      );
      border: none;
      opacity: 1;
      pointer-events: none;
      border-radius: 50%;
      transform-origin: left;
      transition: transform 500ms;
      transform: scale(0);
    }
    &.hov-yellow::after {
      content: "";
      display: block;
      position: absolute;
      width: 100px;
      height: 100px;
      top: auto;
      bottom: -50px;
      left: 0;
      margin-left: 0;
      background-image: none;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(255, 172, 62, 0.3)
      );
      border: none;
      opacity: 1;
      pointer-events: none;
      border-radius: 50%;
      transform-origin: left;
      transition: transform 500ms;
      transform: scale(0);
    }
    &.disabled {
      pointer-events: none;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 60%,
        rgba(255, 172, 62, 0.3)
      );
    }
    &:hover {
      &::after {
        transform: scale(7.3) translate(-21%, 19px);
      }
    }
  }
}
.feature-item:hover {
}
.main-logo-perceval {
  height: 57px;
  background-image: url(/images/home/icons/logo.svg);
  display: inline-block;
  background-size: cover;
  width: 171px;
  @media (max-width: 1500px) {
    height: 40px;
    background-image: url(/images/home/icons/logo-small.svg);
    display: inline-block;
    background-size: cover;
    width: 34px;
    margin-bottom: 0;
  }
  @media (max-width: 1100px) {
    &.opened {
      background-image: url(/images/home/icons/logo-small.svg) !important;
    }
  }
  &.white {
    background-image: url(/images/home/icons/logo-white.svg);
    @media (max-width: 1500px) {
      background-image: url(/images/home/icons/logo-small-white.svg);
    }
  }
}
.header-burger {
  display: none;
  &.white svg {
    color: white !important;
  }
}
.main-nav {
  &.scrolled {
    .main-logo-perceval {
      background-image: url(/images/home/icons/logo.svg) !important;
      @media (max-width: 1500px) {
        background-image: url(/images/home/icons/logo-small.svg) !important;
      }
    }
  }
}
@media (max-width: 1100px) {
  main {
    // margin-top: 98px;
  }
  .header-burger {
    display: block !important;
    &.white svg {
      color: white !important;
    }
    &.opened {
      & svg {
        color: $color-primary !important;
      }
    }
  }

  .main-nav {
    .header-burger {
      margin-bottom: 10px;
    }
    .header-logo {
      margin-bottom: -8px !important;
      margin-right: 0px !important;
    }
    &.scrolled {
      .header-burger {
        margin-bottom: 0px;
        & svg {
          color: $color-primary !important;
        }
      }
      .main-logo-perceval {
        background-image: url(/images/home/icons/logo-small.svg) !important;
      }
      .header-links {
        top: 59px;
      }
    }
    .front-header {
      padding: 10px 20px !important;
      justify-content: space-between !important;
    }
  }
  .main-container .feature-item strong {
    line-height: 25px;
    margin-top: 30px;
  }

  .header-links {
    position: absolute !important;
    transform-origin: top left;
    display: none !important;
    right: 0;
    top: 82px;
    overflow: hidden;
    width: 100%;
    background: rgb(0 0 0 / 46%);
    height: 100svh;
    z-index: 9;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-end !important;
    border-bottom-left-radius: 20px;

    &.show {
      display: flex !important;

      .nav-item a:not(.btn) {
        //color: $color-primary !important;
      }
    }
    & > ul {
      max-width: 260px;
      margin: 0 10px !important;
      position: relative;
      z-index: 2;
      padding-top: 20px !important;
    }
    & > ul > .nav-item {
      display: block;
      padding: 10px 12px !important;
      width: 100%;
      background: white;
      font-weight: 800 !important;
      box-shadow: 0px 2px 7px -5px black;
    }
    .nav-link {
      padding: 0;
    }
    .header-right {
      padding: 0px !important;
      margin-top: 5px !important;
    }

    ul,
    .header-right {
      width: 100%;
      flex-direction: column !important;
      justify-content: flex-start !important;
      align-items: flex-start !important;
    }
  }
}
.section-gradient {
  background: linear-gradient(0deg, #e4e6ee, rgba(255, 255, 255, 0));
}
.ac-main-anim {
  margin-top: -162px;
}
.pj-main-anim {
  //height: 200px;
  display: block;

  .anim-content {
    width: 70%;
    position: relative;
    right: 0;
  }
}
@media (max-width: 1100px) {
  .ac-main-anim {
    margin-top: 40px;
    margin-bottom: 0;
    transform: scale(1.2);
  }
  .main-section-ac-title {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .main-section-ac-cta {
    text-align: center !important;
  }
  .pj-main-anim {
    margin-bottom: 0;

    img {
      display: none;
    }

    .anim-content {
      position: relative;
      width: 100%;
      left: 19%;
    }
  }
}

.divide-section {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100svh;
  min-height: 102svh;
  background-position: center 0px;
  background-repeat: no-repeat;
  background-size: cover;
  // transition: background-position-y 0.1s linear;

  // background-attachment: fixed;
  @media (max-width: 1100px) {
    margin-top: 0px;
    align-items: stretch;
    background-attachment: scroll;
  }

  & .main-cta.in-header {
    @media (max-width: 768px) {
      font-size: 20px !important;
    }
  }
  & .main-cta.out-header {
    @media (max-width: 768px) {
      font-size: 20px !important;
    }
  }

  & .main-cta.hero-cta.cta-sticky {
    position: fixed;
    top: 88px;
    right: 0;
    transition: all 0s !important;
    transform: translateY(0) !important;
    // animation-name: heroCtaAppear;
    animation-duration: 1s;
    animation-fill-mode: forwards;

    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    box-shadow: 5px 0px 5px -1px black;
    @media (max-width: 1100px) {
      top: 10px;
      right: 50%;
      transform: translateX(50%) !important;
      z-index: 9999;
    }
  }

  @keyframes heroCtaAppear {
    0% {
      right: -200px;
    }
    100% {
      right: 0px;
    }
  }

  &.hero-home {
    // background-image: url("/front/bg_home.png");
    .main-cta {
      transform: translateY(0) !important;
    }
    .main-cta.in-header {
      display: none !important;
    }
    @media (max-width: 1100px) {
      // background-image: url("/front/bg_m_home.png");
      background-position: 77% 0px;
    }
    @media (max-width: 768px) {
      background-position: 30% -200px;
    }
    @media (max-width: 500px) {
      background-position: center 0px;
      .main-cta {
        transform: translateY(30px) !important;
      }
    }
    & .title-description {
      max-width: 550px;
      background: radial-gradient(#e7e6ca, transparent 72%);
      @media (max-width: 1100px) {
        max-width: 350px;
      }
      @media (max-width: 768px) {
        background: radial-gradient(#eef8ed, transparent 72%);
        max-width: 250px;
      }
      @media (max-width: 500px) {
        background: radial-gradient(#dbecee, transparent 72%);
      }
    }
  }

  &.hero-ac {
    //background-image: url("/front/bg_ac.png");
    // background-position: center 70px;
    .main-cta.in-header {
      display: none !important;
    }
    @media (max-width: 1100px) {
      background-position: center 0px;
    }
    @media (max-width: 768px) {
      //background-image: url("/front/bg_m_ac.png");
      background-position: center 0px;
    }
    @media (max-width: 500px) {
      background-position: center 0px;
    }
    & .main-h1 {
      @media (max-width: 500px) {
        max-width: 200px !important;
      }
    }
    & .main-cta {
      transform: translateY(130px) !important;
      @media (max-width: 1100px) {
        transform: translateY(20px) !important;
      }
      @media (max-width: 400px) {
        transform: translateY(55px) !important;
      }
    }
    & .sub-header {
      padding-top: 0px !important;
      margin-top: -20px !important;
      @media (max-width: 1100px) {
        padding-top: 80px !important;
      }
    }
    & .title-description {
      max-width: 600px;
      background: radial-gradient(#e8ebd5, transparent 72%);
      margin-bottom: 60px !important;
      @media (max-width: 1100px) {
        max-width: 450px;
      }
      @media (max-width: 768px) {
        max-width: 350px;
      }
    }
  }
  &.hero-cyber {
    // background-image: url("/front/bg_cyber.png");
    // background-position: center 66px;
    .main-cta.in-header {
      display: none !important;
    }
    .main-h1 strong {
      color: white !important;
    }
    @media (max-width: 1100px) {
      background-position: center 0px;
      .main-cta.out-header {
        display: none !important;
      }
      .main-cta.in-header {
        display: flex !important;
        align-self: flex-start !important;
        transform: translateY(0) !important;
      }
    }
    @media (max-width: 768px) {
      // background-image: url("/front/bg_m_cyber.png");
      background-position: 43% 0px;
    }

    @media (max-width: 500px) {
      background-position: -411px 0px;
    }
    @media (max-width: 400px) {
      background-position: -372px 0px;
    }
    & .main-h1 {
      max-width: 1100px;
      @media (max-width: 1100px) {
        max-width: 491px !important;
      }
      @media (max-width: 500px) {
        max-width: 291px !important;
      }
    }
    & .sub-header {
      padding-top: 0px !important;
      @media (max-width: 1100px) {
        padding-top: 70px !important;
      }
    }
    & .main-cta {
      transform: translateY(140px) !important;
      @media (max-width: 1100px) {
        transform: translateY(20px) !important;
      }
      @media (max-height: 800px) {
        transform: translateY(80px) !important; // Agathe screen-size
      }
    }
    & .title-description {
      max-width: 652px;
      background: radial-gradient(#62a39d6e, transparent 71%);
      text-shadow: 0px 0px 20px #b4e3d1, 0 0px 20px #b4e3d1,
        0px 0px 20px #b4e3d1, 0 0px 20px #b4e3d1;
      @media (max-width: 1100px) {
        max-width: 500px;
        text-shadow: none;
        color: black;
      }
      @media (max-width: 768px) {
        max-width: 378px;
      }
    }
  }
  &.hero-domi {
    // background-image: url("/front/bg_domi.png");
    .main-cta.in-header {
      display: none !important;
    }
    @media (max-width: 1100px) {
      background-position: 73% 0px;
    }
    @media (max-width: 768px) {
      // background-image: url("/front/bg_m_domi.png");
      background-position: 73% 0px;
      // background-position: center 30%;
    }
    @media (max-width: 500px) {
      background-position: 73% 0px;
    }
    & .title-section {
      text-align: left;
    }
    & .sub-header {
      align-items: start !important;
      padding-bottom: 0px !important;
      padding-top: 80px !important;
    }
    & .main-cta {
      transform: translateY(0) !important;
    }
    & .main-h1 {
      color: white !important;
      max-width: 800px;
      @media (max-width: 1100px) {
        max-width: 560px !important;
        text-shadow: 0px 0px 10px black;
      }
      @media (max-width: 500px) {
        max-width: 250px !important;
        font-size: 24px !important;
        line-height: 24px !important;
        font-weight: 800 !important;

        & strong {
          font-weight: 900 !important;
          padding-bottom: 20px !important;
          display: block;
        }
      }
      & strong {
        color: white !important;
      }
    }
    & .title-description {
      max-width: 600px;
      text-align: left;
      color: white;
      @media (max-width: 1100px) {
        max-width: 450px;
      }
      @media (max-width: 768px) {
        max-width: 350px;
      }
    }
  }
  &.hero-contact {
    // background-image: url("/front/bg_domi.png");
    background-position: 34% 0px;
    .main-cta.in-header {
      display: none !important;
    }
    @media (max-width: 1100px) {
      background-position: 44% 0px;
    }
    @media (max-width: 768px) {
      // background-image: url("/front/bg_m_domi.png");
      background-position: 42% 0px;
      // background-position: center 30%;
    }
    @media (max-width: 500px) {
      background-position: 47% 0px;
    }
    @media (max-width: 400px) {
      background-position: 47% 0px;
    }
    & .title-section {
      text-align: left;
    }
    & .sub-header {
      align-items: start !important;
      padding-top: 50px !important;
      div {
        align-items: start !important;
      }
      @media (max-width: 1100px) {
        padding-top: 80px !important;
      }
    }
    & .main-cta {
      transform: translateY(0) !important;
    }
    & .main-h1 {
      color: white !important;
      max-width: 840px;
      font-size: 55px !important;
      line-height: 55px !important;
      @media (max-width: 1100px) {
        max-width: 725px !important;
        font-size: 46px !important;
        line-height: 48px !important;
      }
      @media (max-width: 768px) {
        font-size: 37px !important;
        line-height: 37px !important;
        & strong {
          max-width: 400px;
          font-size: 52px;
          line-height: 52px;
        }
      }
      @media (max-width: 500px) {
        max-width: 250px !important;
        font-size: 24px !important;
        line-height: 24px !important;
        font-weight: 800 !important;

        & strong {
          font-weight: 900 !important;
          padding-bottom: 20px !important;
          display: block;
        }
      }
      & strong {
        color: white !important;
      }
    }
    & .title-description {
      max-width: 550px;
      text-align: left;
      color: white;
      a,
      button {
        text-shadow: none !important;
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 26px;
        padding: 15px 20px;
        width: 100%;
        max-width: 350px;
        text-align: center !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        img {
          width: 30px;
          height: 30px;
        }
        @media (max-width: 500px) {
          font-size: 18px;
          line-height: 20px;
          padding: 10px 15px;
          max-width: 270px;
        }
      }
      @media (max-width: 1100px) {
        max-width: 530px;
        text-shadow: 0 0 15px #8baec3, -1px -1px 15px #8baec3,
          1px 1px 15px #8baec3, 0 1px 15px #8baec3, 1px 0 15px #8baec3;
      }
      @media (max-width: 768px) {
        max-width: 350px;
      }
    }
    .contact-btn {
      &.contact-btn-tel {
        @media (max-width: 400px) {
          max-width: 200px !important;
        }
      }
    }
  }
  &.hero-pj {
    // background-image: url("/front/bg_pj.png");
    background-position: center 0px;
    .main-cta.in-header {
      display: none !important;
    }
    @media (max-width: 1100px) {
      background-position: center 0px;
      & .main-cta.out-header {
        display: none !important;
      }
      .main-cta.in-header {
        display: flex !important;
      }
    }
    @media (max-width: 768px) {
      // background-image: url("/front/bg_m_pj.png");
    }
    @media (max-width: 500px) {
      background-position: 43% 0px;
    }
    & .main-h1,
    strong {
      color: white !important;
    }
    & .main-h1 {
      @media (max-width: 500px) {
        max-width: 182px !important;
      }
    }
    & .main-cta {
      transform: translateY(0) !important;
      @media (max-width: 1100px) {
        align-self: start !important;
      }
    }
    & .sub-header {
      padding-top: 0px !important;
      @media (max-width: 1100px) {
        padding-top: 80px !important;
      }
    }
    & .title-description {
      max-width: 650px;
      color: white !important;
      background: radial-gradient(#5b689675, transparent 71%);
      text-shadow: 0px 0px 20px #5b6896, 0 0px 30px #5b6896, 0px 0 30px #5b6896;
      // background: radial-gradient(#5b6896, transparent 72%);

      border-radius: 10px;
      @media (max-width: 1100px) {
        max-width: 500px;
      }
      @media (max-width: 768px) {
        max-width: 410px;
      }
    }
  }
  &.hero-mrp {
    // background-image: url("/front/bg_mrp.png");
    .main-cta.in-header {
      display: none !important;
    }
    @media (max-width: 1100px) {
      background-position: 43% 0px;
    }
    @media (max-width: 768px) {
      // background-image: url("/front/bg_m_mrp.png");
      background-position: 57% 0px;
      // background-position: center 30%;
    }
    @media (max-width: 500px) {
      background-position: 55% 0px;
    }
    & .title-section {
      text-align: left;
    }
    & .sub-header {
      align-items: start !important;
      padding-top: 0px !important;
      @media (max-width: 1100px) {
        padding-top: 80px !important;
      }
    }
    & .main-cta {
      transform: translateY(40px) !important;
    }
    & .main-h1 {
      color: white !important;
      max-width: 840px;
      font-size: 55px !important;
      line-height: 55px !important;
      text-shadow: 0px 0px 10px black;
      @media (max-width: 1100px) {
        font-size: 40px !important;
        line-height: 40px !important;
        max-width: 650px !important;
      }
      @media (max-width: 768px) {
        max-width: 568px !important;
      }
      @media (max-width: 500px) {
        max-width: 286px !important;
        font-size: 24px !important;
        line-height: 24px !important;
        font-weight: 800 !important;

        & strong {
          font-weight: 900 !important;
          padding-bottom: 20px !important;
          display: block;
        }
      }
      & strong {
        color: white !important;
      }
    }
    & .title-description {
      max-width: 600px;
      text-align: left;
      color: white;
      @media (max-width: 1100px) {
        max-width: 450px;
      }
      @media (max-width: 768px) {
        max-width: 350px;
      }
    }
  }
  .main-cta {
    padding: 15px 40px;
    font-size: 28px;
    font-weight: 600;
  }
  .title-section {
    text-align: center;
    padding: 36px 69px;
    border-radius: 20px;
    position: relative;
    z-index: 5;
    @media (max-width: 1100px) {
      text-align: left;
    }
    @media (max-width: 768px) {
      padding: 30px 50px;
    }
    .main-h1 {
      font-size: 70px;
      font-weight: 900;
      line-height: 70px;
      strong {
        font-size: 80px;
        line-height: 80px;
      }
      @media (max-width: 1100px) {
        font-size: 50px;
        line-height: 50px;
        max-width: 550px;
        strong {
          font-size: 60px;
          line-height: 60px;
        }
      }
      @media (max-width: 768px) {
        font-size: 40px;
        line-height: 40px;
        max-width: 400px;
        strong {
          font-size: 45px;
          line-height: 45px;
        }
      }
      @media (max-width: 500px) {
        font-size: 30px;
        line-height: 30px;
        max-width: 400px;
        strong {
          font-size: 35px;
          line-height: 35px;
        }
      }
    }
    & .title-description {
      font-weight: 500;
      font-size: 25px;
      line-height: 28px;
      margin: 30px 0;
      padding: 20px 0;
      @media (max-width: 1100px) {
        margin: 20px 0;
      }
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
        margin: 5px 0;
      }
      @media (max-width: 500px) {
        padding: 10px 0px;
        font-size: 18px;
        line-height: 20px;
      }
    }
    .main-cta {
      padding: 15px 40px;
      font-size: 28px;
      font-weight: 600;
      transform: translateY(100px);
      box-shadow: 0px 7px 19px -5px black;
      @media (max-width: 1100px) {
        transform: translateY(20px);
      }
      @media (max-width: 500px) {
        font-size: 24px;
        padding: 12px 20px;
      }
    }
    .sub-header {
      font-size: 24px;
      padding-bottom: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      // opacity: 0;
      // transition: opacity 1s linear;
      &.loaded {
        // opacity: 1;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1100px) {
          align-items: start;
        }
      }
      height: calc(100% - 80px);
      @media (max-width: 1100px) {
        align-items: start;
        padding-top: 60px;
        padding-bottom: 0px;
      }
    }
  }

  .placeholder {
    // background-color: #f6f6f6;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .placeholder .blur-filter-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: opacity 1s linear;
    backdrop-filter: blur(1px);
  }

  .placeholder .blur-filter-img.loaded {
    opacity: 1;
  }
  .placeholder .blur-filter-img.loaded.finished {
    opacity: 0;
  }

  .blur-filter-img {
    filter: blur(50px);
    /* this is needed so Safari keeps sharp edges */
    transform: scale(1);
  }
}

.article-section {
  max-width: 960px;
  margin: 180px 0;

  header {
    .h3 {
      font-size: 66px;
      line-height: 66px;
      font-weight: 700;
      margin-bottom: 40px;
      @media (max-width: 875px) {
        font-size: 56px;
        line-height: 62px;
      }
    }
  }
  @media (max-width: 768px) {
    max-width: 550px;
    margin: 80px 0;
    .article-description {
      font-size: 20px;
      line-height: 20px;
    }
    header {
      .h3 {
        font-size: 42px;
        line-height: 42px;
        margin-bottom: 20px;
      }
    }
    .article-item {
      padding-bottom: 30px;
      .article-sub-section {
        margin: 30px 0;
      }
      .article-sub-text {
        font-size: 14px;
      }
    }
  }
  .main-cta {
    padding: 15px 40px;
    font-size: 28px;
    font-weight: 600;
    box-shadow: 0px 7px 19px -10px black;
    @media (max-width: 500px) {
      padding: 12px 20px;
      font-size: 20px;
    }

    &.cta-bottom {
      margin: 0px 0px;
    }
  }

  .article-image {
    width: 100%;
    height: 100%;
  }
  .article-item {
    padding-top: 20px;
    .article-description {
      max-width: 600px;
      font-size: 22px;
      line-height: 30px;
      @media (max-width: 768px) {
        max-width: 100%;
        font-size: 18px;
        line-height: 24px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      max-height: 63px;
      min-width: 63px;
      max-width: 80px;
    }
    .article-sub-text {
      font-size: 22px;
      line-height: 30px !important;
      list-style: 28px;
      font-weight: 500;
      padding: 0 20px;
      border-left: 3px solid $color-primary;
    }
    .article-sub-section {
      margin: 60px 0 30px 0;
    }
  }
}
.main-container {
  max-width: 1364px;
  margin: 0 auto;

  .central-section {
    max-width: 1153px;
    margin: 0 auto;
  }

  .main-h2 {
    font-size: 66px;
    line-height: 66px;
    font-weight: 700;
    margin-bottom: 40px;
    &.first-h2 {
      margin: 180px 0;
    }
    @media (max-width: 768px) {
      font-size: 42px;
      line-height: 42px;
      margin-bottom: 20px;
      &.first-h2 {
        margin: 80px 0;
      }
    }
  }

  .main-cta {
    padding: 18px 32px;
    font-size: 24px;
    font-weight: 600;
  }
  .feature-item {
    img {
      height: 200px;
      margin-top: 30px;
    }
    strong {
      display: block;
      font-size: 23px;
      margin-bottom: 12px;
    }
    p {
      font-size: 14px;
    }
  }
  .inverted-feature-item {
    img {
      height: 180px;
      max-width: 180px;
    }
    header {
      strong {
        display: block;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
        color: $color-primary;
      }
    }
    p {
      font-size: 17px;
    }
  }

  .dragon-section,
  .bottom-section {
    margin: 100px 0 !important;
  }

  .dragon-anim-container {
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  .bottom-section p {
    font-size: 24px;
    margin-bottom: 50px;
  }
}
.bottom-message {
  background: $color-primary;
  color: white;
  padding: 58px 20px;
  font-size: 35px;
}
.main-footer {
  padding: 0px 0px 40px 0;
  background: #e4e6ee;
  background: linear-gradient(0deg, #4b5cbf57 28%, white 96%);
  .footer-logo {
    height: 84px;
    margin-left: -38px;
    margin-top: -13px;
  }
  .row {
    max-width: 1070px;
    margin: 0 auto;
  }
  .col-md-8 {
    margin-top: 68px;
    @media (max-width: 768px) {
      margin-top: 0;
    }
  }
  .footer-title {
    font-size: 16px;
    position: relative;
    padding: 5px 0;
    margin-top: 50px;
    margin-bottom: 30px;
    display: block;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      border-top: 1px solid black;
    }
  }
  p,
  a {
    display: block;
    color: $color-primary;
    margin: 20px 0;
    font-size: 16px;
  }
  p.small {
    font-size: 13px;
  }
}

@media (max-width: 1364px) {
  .navbar,
  .main-container {
    padding: 0 30px;
  }
}

@media (max-width: 768px) {
  .main-container {
    padding: 0 20px;
  }

  .main-cta {
    padding: 18px;
  }

  .feature-item {
    img {
      height: 130px;
    }
  }

  .dragon-anim-container {
    margin-top: 0;
    width: calc(100% + 40px);

    margin: 0 -20px 0 -20px;
    .dragon-anim-content {
      transform-origin: bottom center;
    }
  }

  .bottom-section {
    p {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  .bottom-message {
    padding: 20px 20px;
    font-size: 18px;
  }
  // .emma-picture {
  //   margin-left: -70px;
  // }
  .main-footer {
    .footer-logo {
      margin: 0 !important;
    }
  }
  .special-footer-section {
    display: block !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}
@media (max-width: 1100px) {
  .special-footer-section {
    display: block !important;
    max-width: 66%;
    flex: 0 0 66%;
  }
  .footer-logo {
    margin-left: 0 !important;
    max-width: 80%;
  }
}
.ac-strong-sub {
  font-size: 22px;
  margin-top: 20px;
  line-height: 28px;
}
.espace-en-ligne-h3 {
  max-width: 388px;
}
.assurance-en-ligne-h3 {
  max-width: 500px;
}
.dragon-text-top {
  max-width: 500px;
}
.dragon-text-bottom {
  max-width: 524px;
}
.jargon-pj {
  font-size: 18px;
}
.social-links p {
  font-size: 24px;
}
.carouselc {
  .quot-left,
  .quot-right {
    position: absolute;
    font-size: 70px;
    text-align: center;
  }
}
.carouselc .quot-left {
  left: 0;
  top: -55px;
}
.carouselc .quot-right {
  right: 0;
  bottom: -20px;
}
@media (max-width: 500px) {
  .social-links p {
    font-size: 18px;
  }
  .contact-arrow {
    display: none;
  }
  .carouselc-main-title {
    max-width: 290px;
  }
  .carouselc ul {
    height: 350px !important;
  }
  .carouselc nav {
    margin-top: 10px !important;
  }
  .ac-strong-sub {
    font-size: 21px;
  }
  .accompagne-au-bout {
    max-width: 400px !important;
  }
  .juristes-engages {
    max-width: 370px !important;
  }
  .defendons-vos-interets {
    max-width: 280px !important;
  }
  .main-h1-pj {
  }
  .bottom-section-title {
    max-width: 220px;
  }
  .conclude-title {
    max-width: 300px;
  }
  #banner {
    height: 250px !important;
    transform: scale(1.8) !important;
  }
  #remerciement {
    height: 200px !important;
  }
  .espace-en-ligne-h3 {
    max-width: 276px;
  }
  .assurance-en-ligne-h3 {
    max-width: 233px;
  }
  // .emma-picture {
  //   margin-left: -135px;
  // }
  .dragon-text-top {
  }
  .dragon-text-bottom {
  }
}

.carouselc {
  display: block;
  width: 100%;
  max-width: 900px;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 0px;

  nav {
    div {
      transform: scale(0.7);
      transition: 400ms;
      cursor: pointer;
      padding: 10px;
      border-radius: 100%;
      border: 1px solid transparent;
      filter: grayscale(1);
    }
    .current-nav {
      border: 1px solid $color-primary;
      transform: scale(1);
      filter: grayscale(0);
    }
  }

  ul {
    list-style: none;
    margin: 10px 0 !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    display: flex;
    flex-direction: row;
    height: 400px;

    position: relative;

    li {
      display: flex;
      flex-direction: column;
      position: absolute;
      opacity: 0;

      transition: 400ms;
      margin: 0 80px;
      max-width: 770px;

      p {
        font-size: 24px;
        margin: 45px auto;
        position: relative;
        // &::before {
        //   content: "“";
        //   position: absolute;
        //   left: -50px;
        //   top: -80px;
        //   font-size: 120px;
        //   color: $color-primary;
        //   height: 40px;
        // }
        // &::after {
        //   content: "”";
        //   position: absolute;
        //   right: -50px;
        //   bottom: 0px;
        //   font-size: 120px;
        //   color: $color-primary;
        //   height: 40px;
        // }
      }
      i,
      strong {
        font-size: 26px;
        font-style: italic;
      }
      strong {
      }

      &.current-slide {
        opacity: 1;
      }
    }
  }
}
.parag {
  font-size: 24px !important;
  line-height: 32px !important;
}
.reduced-cta {
  margin: 60px 0 120px 0;
}
.main-h3 {
  font-size: 38px;
  font-weight: 700;
  margin-top: 116px;
  margin-bottom: 50px;
}
@media (max-width: 900px) {
  .carouselc ul li p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .main-h3 {
    font-size: 26px;
  }
  .reduced-cta {
    margin: 60px 0;
  }
  .parag {
    font-size: 18px !important;
    line-height: 24px !important;
  }
  .carouselc-container {
    .main-h2 {
      font-size: 22px;
    }
  }
  .carouselc {
    ul {
      margin-top: 0px !important;

      li {
        margin: 0;
        strong,
        i {
          font-size: 22px;
        }
        p {
          font-size: 16px;
          line-height: 22px;
          margin: 20px 0 !important;

          // &::before {
          //   font-size: 71px;
          //   left: 0px;
          //   top: 0px;
          //   display: none;
          // }
          // &::after {
          //   font-size: 71px;
          //   right: 0px;
          //   bottom: -40px;
          //   display: none;
          // }
        }
      }
    }

    nav {
      gap: 1rem !important;
      margin-top: 30px;
      div {
        width: 50px;
        height: 50px;
        padding: 4px;
      }
    }
  }
  .contact-input {
    min-width: 0 !important;
  }
}

.contact-input {
  min-width: 340px;
  margin-top: 0.25rem !important;
}

.timeline-list-item {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 3px;
    // background: linear-gradient(
    //   90deg,
    //   rgb(19 13 165 / 41%),
    //   #f1f2fe 70%,
    //   rgb(19 13 165 / 41%)
    // );
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    left: 0px;
    top: 5px;
    // background-color: #cecef6;
    // background-color: #130da5;
    //background-color: #f1f2fe;
    // background-image: url(/images/icons/chevron-down.png);
    // background: linear-gradient(
    //   90deg,
    //   rgb(61 117 255),
    //   rgb(255 255 255) 70%,
    //   rgb(61 117 255)
    // );
    // box-shadow: 0px 0px 3px 0px gray;
    // background-size: 16px;
    background-repeat: repeat-y;
    background-position: center;
  }
}

.cta-with-arrow {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 90px;
    // background-color: #f1f2fe;
    // background: linear-gradient(
    //   90deg,
    //   rgb(61 117 255),
    //   rgb(255 255 255) 70%,
    //   rgb(61 117 255)
    // );
    width: 3px;
    left: 0px;
    top: -47px;
    border-bottom-left-radius: 15px;
  }
  &::after {
    content: "";
    position: absolute;
    height: 3px;
    // background-color: #f1f2fe;
    // background: linear-gradient(
    //   0deg,
    //   rgb(61 117 255),
    //   rgb(255 255 255) 70%,
    //   rgb(61 117 255)
    // );
    width: 50%;
    left: 0px;
    top: 40px;
    border-bottom-left-radius: 15px;
  }
}

.cyber-accordion-title.sticky-head {
  @media (max-width: 768px) {
    font-size: 28px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    border-radius: 0px !important;
    top: 68px !important;
    font-weight: 800 !important;
  }
  @media (max-width: 500px) {
    font-size: 24px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    border-radius: 0px !important;
    top: 68px !important;
    font-weight: 600 !important;
  }
}

@media (max-width: 768px) {
  .timeline-list-item::before {
    left: 8px;
    width: 7px;
  }
  .cyber-list-content {
    padding: 15px 10px !important;
  }
  .cyber-container .article-section {
    margin: 0px !important;
  }

  .cta-with-arrow {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 96px;
      // background-color: #3e75ff;
      width: 7px;
      left: 8px;
      top: -47px;
      border-bottom-left-radius: 15px;
    }
    &::after {
      content: "";
      position: absolute;
      height: 7px;
      // background-color: #3e75ff;
      width: 50%;
      left: 8px;
      top: 43px;
      border-bottom-left-radius: 15px;
    }
  }
}

.cyber-main-img {
  position: relative;
  top: -60px;
}

@media (max-width: 1165px) {
  .cyber-main-img {
    top: 0px;
    max-height: 326px;
  }
}

.cyber-desktop-cta {
  display: flex;
}
.cyber-mobile-cta {
  display: none;
}
@media (max-width: 768px) {
  .cyber-desktop-cta {
    display: none !important;
  }
  .cyber-mobile-cta {
    display: block;
  }
}

.wave-container {
  height: 0vh;
  background-color: #20b2aa;
  position: relative;
}

.wave-container::before {
  content: "";
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: -1px;
  left: 0;
  background-size: auto;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  80' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 59L50 55C100 51 200 44 300 30C400 15 500 -6 600 1C700 8 800 44 900 59C1000 73 1100 66 1150 62L1200 59V80H1150C1100 80 1000 80 900 80C800 80 700 80 600 80C500 80 400 80 300 80C200 80 100 80 50 80H0V59Z' fill='%23ffffff'/></svg>");
  @media (max-width: 768px) {
    height: 4px;
  }
  @media (max-width: 500px) {
    height: 13px;
    bottom: -1px;
  }
}

// @media (max-width: 850px) {
//   .wave-container::before {
//     height: 40.5px;
//   }
// }
.wave-container-header {
  height: 0vh;
  background-color: #20b2aa;
  position: relative;
  z-index: 12;
  transform: rotate(180deg);
}

.wave-container-header::before {
  content: "";
  width: 100%;
  height: 2.5px;
  position: absolute;
  bottom: 0px;
  left: 0;
  background-size: auto;
  background-repeat: repeat no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  80' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 59L50 55C100 51 200 44 300 30C400 15 500 -6 600 1C700 8 800 44 900 59C1000 73 1100 66 1150 62L1200 59V80H1150C1100 80 1000 80 900 80C800 80 700 80 600 80C500 80 400 80 300 80C200 80 100 80 50 80H0V59Z' fill='%23ffffff'/></svg>");
  @media (max-width: 768px) {
    height: 4px;
  }
  @media (max-width: 500px) {
    height: 8px;
    bottom: -1px;
  }
}

.partner-page {
  .article-section .h3 {
    font-size: 40px !important;
    line-height: 44px !important;
    margin-bottom: 20px !important;
  }
}

.garantie-list strong {
  font-weight: 800;
}
.hero-partner {
  // background-image: url("/front/bg_home.png");
  margin-top: 120px;
  padding: 0 20px;

  .main-h1 {
    font-size: 80px;
    line-height: 80px;
    text-align: center;
    @media (max-width: 1100px) {
      font-size: 60px;
      line-height: 60px;
      text-align: left;
    }
    @media (max-width: 500px) {
      font-size: 35px;
      line-height: 35px;
    }
  }
  .title-description {
    font-weight: 500;
    font-size: 25px;
    line-height: 28px;
    margin: 30px 0;
    padding: 20px 0;
    text-align: center;
    @media (max-width: 1100px) {
      text-align: left;
    }
  }
}

.fwb {
  font-weight: 900;
}
