input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-control {
  box-shadow: none !important;
  border-radius: 10px;
  padding: 10px !important;
  padding-right: 15px !important;
  height: 48px;
  background: white;
  border: 1px solid #d4d4d4;
  color: black;
  font-size: 16px;
}
.form-control:focus {
  border-color: $color-primary !important;
  background-color: white;
  color: black;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: white;
  opacity: 1;
}
input[type="email"][disabled] {
  border-style: solid !important;
}
label:not(.mantine-Switch-label):not(.mantine-Switch-track):not(
    .mantine-InputWrapper-label
  ):not(.mantine-SegmentedControl-label) {
  margin-bottom: 4px;
  font-weight: bold;
  margin-bottom: 3px;
  margin-top: 5px;
  color: black;
  font-size: 14px;
  //padding-left: 7px !important;

  .label-text {
    color: $color-primary;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.mantine-SegmentedControl-label {
  margin-bottom: 0;
  margin-top: 0;
}

.readonly {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.readonly label {
  width: 30%;
  margin: 0;
}
.form-generator .form-group {
  margin-top: 40px !important;
}
.form-group {
  transition: 400ms;
  // animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  //background-color: $color-default !important;
  background: linear-gradient(-45deg, $color-default 50%, $color-blue 52%);
  background-size: 250% 400%;
  animation: gradient-appear 1s ease;
  animation-fill-mode: forwards;
  transition: background-position 1s;
  background-position: 100% 50%;

  &:focus-within,
  &.has-value {
    //background-color: $color-blue !important;
    background-position: 0% 50%;
  }
  &.noBg {
    background: rgba(255, 255, 255, 0) !important;
  }
}

@keyframes fade-in-fwd {
  0% {
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

.readonly .input-group {
  width: 70%;
}
.readonly .form-control {
  background: transparent !important;

  box-shadow: none !important;
  width: 100%;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.input-group .input-group-append {
  background: white;
  border-radius: 0px;
  padding-right: 12px;
  border: 1px solid #d4d4dd;
  border-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.input-group > .input-group-append > .input-group-text {
  padding-left: 0;
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  border: 1px solid $color-secondary;
  background-color: white;
  box-shadow: 0px 1px 6px -2px $color-secondary !important;
}

.form-control.custom-range {
  border: none !important;
}
.range-input-discrete-label {
  font-size: 9px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    height: 15px;
    width: 1px;
    background: grey;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.form-control.custom-range:focus {
  box-shadow: none !important;
}

.form-control.range-inp-value {
  text-align: center !important;
  border-radius: 5px !important;
  padding: 0 !important;

  &:focus {
    transform: scale(1.6);
  }
}
.custom-range::-webkit-slider-thumb {
  background: $color-secondary;
  margin-top: -11px;
  height: 25px;
  width: 25px;
  border: 3px solid white;
  box-shadow: 0 1px 4px 1px rgb(202, 201, 201);
}

.custom-range::-moz-range-thumb {
  background: $color-secondary;
}

.custom-range::-ms-thumb {
  background: $color-secondary;
}
.custom-range::-webkit-slider-runnable-track {
  background-color: $color-grey;
  height: 2px;
}
.custom-range::-moz-range-track {
  background-color: $color-secondary;
}
.custom-range::-ms-track {
  background-color: $color-secondary;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $color-primary;
  background: #c5c5c5;
}
.big-checkbox.custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: $color-secondary;
}
.big-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #dedede;
  background: #3ab165 !important;
  opacity: 1;
}
.ac-results .ac-res:hover {
  background-color: #e8e8e8;
}
input:disabled,
input[disabled] {
  color: white;
  background: white;
  cursor: pointer;
}

.big-checkbox {
  height: 30px;
  transform: scale(1.5);
  margin-left: 14px;
  margin-right: -6px;
}
.custom-switch .custom-control-label {
  &:focus {
    box-shadow: none;
  }
  &:before {
    left: -3.5rem;
    width: 40px;
    height: 22px;
  }
  &:after {
    width: 17px;
    height: 17px;
    left: -54px;
  }
  &:before,
  &:after {
    border-radius: 30px;
    top: 42%;
    transform: translateY(-50%);
  }

  &:after {
    transform: translate(0px, -50%) !important;
  }
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translate(19px, -52%) !important;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #3ab165;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  opacity: 1;
}

.check-btn-button img {
  max-height: 300px;
}

@media (max-width: 500px) {
  .check-btn-button img {
    max-height: 130px;
  }
}

.pulsing {
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 57%;
    background: rgba(255, 255, 255, 0);
    border: 1px solid $color-primary;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    transform-origin: center;
    transform: translate(-50%, -50%) scale(0);
    z-index: 99;
    animation-name: pulse-out;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}
.pulsing-light {
  &::after {
    border: 2px solid $color-secondary;
    animation-delay: 120ms;
  }
}
.pulsing-rect {
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 57%;
    background: rgba(4, 12, 165, 0.2);
    width: 80px;
    height: 80px;
    transform-origin: center;
    transform: translate(-50%, -50%) scale(0);
    z-index: 99;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    border: 0px solid $color-secondary;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    backdrop-filter: hue-rotate(340deg);
    //border: 0;
    animation-timing-function: linear;
    animation-name: pulse-fullout;
  }
}

@keyframes pulse-out {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}
@keyframes pulse-fullout {
  0% {
    transform: translate(-50%, -50%) scale(0.95);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.3);
    opacity: 0;
  }
}

textarea[disabled],
textarea[readonly],
textarea.form-control[disabled],
textarea.form-control[readonly],
input.form-control[disabled],
input.form-control[readonly],
input[type="text"][disabled],
input[type="text"][readonly],
input[type="password"][disabled],
input[type="password"][readonly],
input[type="email"][disabled],
input[type="email"][readonly],
input[type="number"][disabled],
input[type="number"][readonly],
[type="text"].form-control[disabled],
[type="text"].form-control[readonly],
[type="password"].form-control[disabled],
[type="password"].form-control[readonly],
[type="email"].form-control[disabled],
[type="email"].form-control[readonly],
[type="tel"].form-control[disabled],
[type="tel"].form-control[readonly],
[contenteditable].form-control[disabled],
[contenteditable].form-control[readonly] {
  box-shadow: none;
  border-bottom: 1px solid #ddd;
}

.mantine-Input-input:disabled {
  color: rgb(33, 33, 33) !important;
}
