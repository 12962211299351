@import "./colors.scss";
@import "./frontpages.scss";
@import "./inputs.scss";
@import "./buttons.scss";
@import "./menu.scss";
@import "./timer.scss";
@import "./cardelement.scss";
@import "./cardelement.scss";
@import "./ordered-list.scss";

html,
body,
#root {
  background-color: #ffffff;
  width: 100%;
  min-height: 100vh;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
#__next {
  min-height: 94vh;
}
body {
  margin: 0;
  font-family: Satoshi;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: #212529;
  font-size: 16px;
}
h1 {
  margin-bottom: 0;
  font-size: 1.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
a {
  color: $color-primary;
}
.progress_text {
  font-size: 8px;
  color: #4c4c4c;
  position: absolute;
  opacity: 1;
  transition: opacity 1000ms ease-out;
  mix-blend-mode: difference;
  width: 100%;
  text-align: right;
  height: 10px;
  line-height: 10px;
  border-radius: 10px;
  top: 6px;
}
.overlay_loading {
  position: absolute;
  z-index: 999;
  height: 104%;
  width: 104%;
  left: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffb8;
}

.btn-group {
  display: flex;
  flex-direction: column;
}
.btn-group button {
  min-height: 50px;
  margin-right: 0;
  margin-bottom: 10px;
  background: white;
  padding: 8px 15px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  text-transform: none;
  border-radius: 5px !important;
  flex: 1;
}
.btn.active {
  background: #349ff5;
  color: white;
}
.btn.active .fa {
  line-height: 17px;

  padding: 10px;
  border-radius: 100%;
  background: #27c99d;
  font-size: 18px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
.card-header {
  background: #2196f3 linear-gradient(180deg, #42a6f5, #2196f3) repeat-x;
  color: white;
}
.breadcrumb-custom {
  background: #299af3;
}
.breadcrumb-custom .col {
  height: 25px;
}
.breadcrumb-custom .col span {
  color: white;
  position: absolute;
  line-height: 25px;
  left: 3%;
}
.breadcrumb-custom .col:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #aad2f3;
  border-right: 13px solid #c0e3ff;
  transform-origin: 100% 100%;
  transform: skewX(30deg);
  transition: 1s;
}
.breadcrumb-custom .col.active:before {
  background: #2196f3 linear-gradient(180deg, #42a6f5, #2196f3) repeat-x;
  border-right: 13px solid rgb(107, 190, 255);
}

.Toastify__toast {
  font-family: Satoshi !important;
  border-radius: 5px !important;
  font-weight: 500;
}
.card {
  overflow: hidden;
}
.card-header {
  text-align: center;
}
.form-info {
  position: relative;
}
.grecaptcha-badge {
  opacity: 0;
}

.text-grey {
  color: grey;
}
.product-section {
  color: #293148;
}
.react-calendar {
  border: none !important;
}
.react-calendar__tile {
  border-radius: 40px;
}
.react-calendar__tile--active {
  background: $color-primary !important;
  box-shadow: 0px 1px 9px -2px grey;
}
.react-calendar__navigation button {
  border-radius: 40px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 24px;
  padding: 0;
  padding-bottom: 2px;
}
.react-calendar__navigation button[disabled] {
  opacity: 0;
}
.react-calendar__tile {
  transition: 400ms;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-calendar__navigation__label__labelText {
  font-size: 1.3rem !important;
  text-transform: capitalize;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: none;
}
.react-calendar__month-view__days__day--weekend {
  color: black !important;
}
.react-calendar__tile--active {
  color: white !important;
}
.react-calendar__tile--now {
  background: #cecece;
}
.text-primary {
  color: $color-primary !important;
}
.feature-title {
  font-size: 24px;
}
.optin-btn-dl {
}
.optin-text-dl {
  display: none;
}
.custom-modal-backdrop {
  display: none;
  top: 0px;
  left: 0px;
  background: radial-gradient(
    rgb(185 185 185 / 80%),
    rgb(0 5 16 / 70%),
    transparent
  );
  z-index: 9999;
  overflow-y: auto;
  transition: 500ms;
  opacity: 0;
  transform: scale(3);
}
.custom-modal-backdrop.opened {
  opacity: 1;
}
.text-alternate {
  animation: slideAlternate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}
.form-header-container {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: transparent;
  z-index: 99;
  left: 0;
  top: 0;

  &.is-fixed {
    position: fixed;
    background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0)
    );
    background: -webkit-linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0)
    );
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(255, 255, 255, 0.7)),
      color-stop(100%, rgba(255, 255, 255, 0))
    ); /* Chrome,Safari4+ */
  }
}

.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*Responsive CSS triangles. Resize the Result section to see the triangles in action.*/

/*Down pointing*/
.triangle-down {
  width: 10%;
  height: 0;
  padding-left: 10%;
  padding-top: 10%;
  overflow: hidden;
}
.triangle-down:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin-left: -500px;
  margin-top: -500px;

  border-left: 500px solid transparent;
  border-right: 500px solid transparent;
  border-top: 500px solid $color-secondary;
}

@keyframes slideAlternate {
  0% {
    transform: translateX(0%);
  }
  30% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-100%);
  }
  80% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.form-generator-container {
  transform: translate(0px, -50px);
  opacity: 0;
  animation: slideTopHide;
  animation-duration: 800ms;
  animation-fill-mode: forwards;
  background-color: #f7f7f7;
}
.form-generator-container.animateTop {
  animation: slideTopShow;
  animation-duration: 400ms;
}

@keyframes slideTopHide {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(100px);
    opacity: 0;
  }
  51% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideTopShow {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}
.lds-ripple {
  position: relative;
  width: 90px;
  height: 90px;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  transition: 400ms;
}
.lds-ripple div {
  position: absolute;
  border: 2px solid $color-primary;

  opacity: 1;
  border-radius: 50%;
  // box-shadow: 0 0 4px 1px grey;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  border-color: $color-secondary;
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 45px;
    left: 45px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 90px;
    height: 90px;
    opacity: 0;
  }
}

.price-card {
  position: relative;
  padding-bottom: 15px !important;

  // &::before {
  //   content: "";
  //   background: white;
  //   position: absolute;
  //   bottom: -53px;
  //   height: 100%;
  //   width: 50%;
  //   left: 0;
  //   transform: skew(0deg, 29deg);
  //   z-index: -1;
  //   // border-bottom: 1px solid #3e75ff9e;
  //   box-shadow: 3px 18px 11px -19px #00278969;
  // }
  // &::after {
  //   content: "";
  //   background: white;
  //   position: absolute;
  //   bottom: -53px;
  //   height: 100%;
  //   width: 50%;
  //   right: 0;
  //   transform: skew(0deg, -29deg);
  //   z-index: -1;
  //   // border-bottom: 1px solid #3e75ff9e;
  //   box-shadow: 3px 18px 11px -19px #00278969;
  // }
}

.payment-success-text {
  padding: 0 82px;
}

@media (max-width: 768px) {
  .merlin-hello {
    display: none;
  }
  .payment-success-text {
    padding: 0 20px;
  }
  .custom-dropdown-toggler.opened {
    padding-bottom: 0px !important;
    margin-bottom: -24px;
    .nav-item {
      padding: 20px 14px !important;
    }
    .custom-dropdown {
      gap: 0rem !important;
    }
  }
}
.bridge-text {
  position: absolute;
  left: 50%;
  top: -55px;
  transform: translateX(-50%);
  font-size: 30px;
  color: $color-primary;
}

.custom-dropdown {
  position: absolute;
  background: white;
  border-radius: 10px;
  transform: translateY(0px) scale(0);
  transition: 400ms;
}
.custom-dropdown-toggler:focus-within {
  .custom-dropdown {
    transform: translateY(0px) scale(1);
  }
}
li.nav-item.custom-dropdown-toggler {
  .custom-dropdown-icon {
    width: 16px;
    margin-left: 12px;
  }
  &.opened {
    padding: 0px 30px !important;
    & > a {
      display: none;
    }
    .custom-dropdown {
      transform: translateY(0px) scale(1);
      position: inherit;
      background: transparent;
      gap: 2.5rem;
    }
  }
}
.Toastify__toast--success {
  background: #3e75ff;
}
.header-burger::after {
  content: "";
  display: none;
}

@media (max-height: 300px) {
  .btn-return-404 {
    bottom: 10px !important;
  }
  .image-404 {
    height: 110px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.bg-default {
  background-color: #f7f7f7 !important;
}
.company-result:hover {
}
.navigation-block {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;
    bottom: 25%;
    background: #d6d6d6;
  }

  &:not(.first):not(.last) {
    &.active::after {
      background: linear-gradient(90deg, #030ca5 50%, #d6d6d6 50%);
    }

    &.complete::after {
      background: #030ca5;
    }
  }
  &.first::after {
    background: linear-gradient(90deg, transparent 50%, #d6d6d6 55%);
  }
  &.first.complete::after {
    background: linear-gradient(90deg, transparent 50%, #030ca5 50%);
  }
  &.last::after {
    background: linear-gradient(90deg, #d6d6d6 50%, transparent 50%);
  }
  &.last.active::after {
    background: linear-gradient(90deg, #030ca5 50%, transparent 50%);
  }
  &.last.complete::after {
    background: linear-gradient(90deg, #030ca5 50%, transparent 50%);
  }
}
.shadow-small {
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 2px -3px;
}
.shadow-small-up {
  box-shadow: rgb(0 0 0 / 20%) 0px -1px 2px 1px;
}
.bg-blue {
  background-color: #f1f2fe !important;
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.company-nav-wheel {
  &::before {
    content: "";
    height: 100%;
    width: 40px;
    background: linear-gradient(90deg, #f7f7f7, rgba(255, 255, 255, 0));
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
  &::after {
    content: "";
    height: 100%;
    width: 40px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #f7f7f7);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
  }
}
.arrow-box-up {
  position: relative;
}
.arrow-box-up:after,
.arrow-box-up:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-box-up:after {
  border-color: rgba(253, 253, 245, 0);
  border-right-color: #f8f8fb;
  border-width: 10px;
  margin-top: -10px;
}
.arrow-box-up:before {
  border-color: rgba(227, 227, 227, 0);
  border-right-color: #060da5;
  border-width: 12px;
  margin-top: -12px;
}

.rounded {
  border-radius: 10px !important;
}
.rotate {
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.very-slow {
  animation-duration: 5s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.merlin-content {
  background: linear-gradient(-45deg, $color-default 50%, white 52%);
  background-size: 250% 400%;
  animation: gradient 1s ease;
  animation-fill-mode: forwards;
  //color: white;
}

@keyframes gradient {
  0% {
    background-position: -20% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.bg-picon {
  background: rgba(183, 179, 179, 0.25) !important;
}
.saved-cb-btn {
  background: linear-gradient(169deg, #f7f7f7 50%, #f1f1f1 50%) !important;
  color: #4e4d4d !important;
  transition: 400ms;

  &.checkedBtn {
    background: linear-gradient(169deg, #353a99 50%, #3d429f 50%) !important;
    color: white !important;
    &:hover {
      color: white !important;
    }
  }

  &:hover {
    color: #030ca5 !important;
  }
}

.react-calendar__tile:disabled {
  background-color: rgba(255, 255, 255, 0.001) !important;
  color: #b8b8b8 !important;
}
input[type="date"] {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 1.2em;
}
select.form-control::-ms-expand {
  display: none;
}
select.form-control {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
.select-input-container {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  &:after {
    content: "⌄";
    position: absolute;
    pointer-events: none;
    width: 30px;
    height: calc(100% - 6px);
    right: 4px;
    top: 1px;
    background: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    font-size: 18px;
    // transform: rotate(90deg);
  }
}
input::placeholder,
input::-webkit-input-placeholder {
  line-height: normal !important;
}

.mantine-Group-root .mantine-Text-root {
  padding-top: 3px;
}

@media (max-width: 500px) {
  .sumary-wrapper {
    .sumary-product-info {
      max-width: 228px !important;
    }
  }
}
.CircularProgressbar-text {
  // transform: translate(-18%, 10%);
}
