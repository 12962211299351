.custom-ol {
  list-style: none;
  padding-left: 0px;

  & li {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    padding-bottom: 40px;
    width: 100%;

    &:not(:last-child):after {
      content: "";
      width: 1px;
      position: absolute;
      height: 100%;
      background: #707070;
      left: 34px;
      opacity: 0;
      animation-name: animFade;
      animation-delay: 500ms;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }

    & .number-partner {
      min-width: 68px;
      min-height: 68px;
      background: #ffe8b5;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: inset 0px 3px 5px -3px black;
      color: #ef9600;
      font-size: 33px;
      font-weight: 800;
      position: relative;
      z-index: 2;
    }
    & .number-DOMI {
      min-width: 68px;
      min-height: 68px;
      background: #040ca5;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: inset 0px 3px 5px -3px black;
      color: white;
      font-size: 33px;
      font-weight: 800;
      position: relative;
      z-index: 2;
    }

    & .content {
      margin-left: 40px;
      font-size: 16px;

      & .title {
        font-weight: 600;
      }
    }
  }
}
@media (min-width: 768px) {
  .custom-ol {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: row;

    & li {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      width: calc(100% / var(--nbItems));

      &:not(:last-child):after {
        content: "";
        width: 100%;
        height: 1px;
        left: calc(50% + 34px);
        top: 34px;
      }

      & .title {
        margin: 10px 0px;
      }

      & .content {
        text-align: center;
        margin-left: 0px;
      }
    }
  }
}

@keyframes animFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
