.hover-scale-up {
  transition: 200ms;
}
.hover-scale-up:hover {
  transform: scale(1.1);
}

.hover-logo {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition: ease 1s;
}

.hover-logo:hover {
  box-shadow: 0px 2px 8px 1px rgba(4, 11, 165, 0.21);
}

.word-change {
  display: block !important;
  position: relative;
  p {
    display: inline-block;
    vertical-align: top;
    margin: 0;
  }

  .word {
    position: absolute; /* makes words stack on top of each other */
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .letter {
    display: inline-block;
    transform-origin: 50% 50% 25px;
  }

  .letter.out {
    transform: rotateX(90deg);
    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .letter.behind {
    transform: rotateX(-90deg);
  }

  .letter.in {
    transform: rotateX(0deg);
    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}
